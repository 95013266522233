<template>

<div class="field-table flex-1 flex flex-col  max-h-full">
    <sub-header buttonText="Create client"
        :handleClick="createNewClient"
        :showBtn="true" />
    <div class="flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar mx-6">
        <span v-if="isLoading" class="flex h-44 min-w-full mt-6 bg-white items-center justify-center rounded-md">
            <loader class="my-5" height="32px" width="32px" :loading="isLoading" />
                Loading
        </span>
        <dv-table v-else :headers="columns" :rows="getFilteredResult" :handleRowClick="viewClientInfo" :showFooter="false" :isClickable="true" >
            <template v-slot:items="{ row }">

                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{row.name}}
                </td>
                <!-- <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span class="inline-flex rounded-sm  px-2 text-xs font-semibold leading-5 "
                        :class="row.is_custom ? 'bg-green-100 text-green-800' : 'bg-gray-200 text-gray-800' ">{{row.is_custom? 'Custom': 'system Generated'}}</span>
                </td> -->
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <font-awesome-icon icon='input-text' class="icon alt" /> {{row.prefix}}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{row.retention_period}}
                </td>
                <td class="whitespace-nowrap h-full px-3  py-4 text-sm text-gray-500">
                    <div class="flex  justify-between items-center m-0">
                        {{row.id.substring(0, 8)}}
                        <font-awesome-icon icon="angle-right" class=" text-primary w-32 text-2xl self-center cursor-pointer hover:opacity-70" />
                    </div>
                </td>

            </template>
            <template v-slot:no-data>
            </template>

        </dv-table>

    </div>

</div>

</template>

<script>
// import Input from "@/components/input";
import axios from "@/axios";
// import Button from "@/components/button";
import SubHeader from "@/components/SubHeader";
import dvTable from "@/components/devliumComponent/field-table/dv-table";
// import { columns, rows } from "./fieldTableData";
// import Button from "@/components/button";
import loader from "@/components/loader";

export default {
    name:"clients-list",
    components: {
        // "neo-input":Input,
        // "neo-button":Button,
        // axios,
        loader,
        SubHeader,
        dvTable,
        // "neo-button": Button,
    },
    data(){
        return{
            columns: [
                {label:"Client name", field:'name'},
                {label:"Prefix", field:'subdomain'},
                {label:"Retention period", field:'client_retention_period'},
                {label:"Client id", field:'id'},
            ],
            isLoading:false,
            clients:[]
            

        }
    },
    async mounted(){
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch('fetchTenantId')
        }
        await this.fetchClients()
    },
    methods:{

        async fetchClients(){
            const tenant_id = this.$store.getters.getTenantId
            this.isLoading = true

            const data = await axios.get(`/tenant/${tenant_id}/client`)
            this.clients = data.data
            this.isLoading = false

            // this.columns = 

            // {
            //     params:{
            //         id : this.$store.getters.getTenantId,
            //     }
            // }
            // )
            console.log(data)


        },
        viewClientInfo(data){
            console.log(data, "data")
            this.$router.push({
                name: "Edit Client",
                params: { id: data.id },
            });
        },
        createNewClient(){
            this.$router.push({
                name: "Create Client",
            });
        }

    },
    computed:{
        getFilteredResult(){
            return this.clients
        }
    }
}
</script>

<style scoped>

</style>